import LandingPage from './components/landing-page/LandingPage';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
const MEASUREMENT_ID = "G-ZJB62LMYNV";
ReactGA.initialize(MEASUREMENT_ID);

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home page" });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={LandingPage} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
