import "./subscribe.css";
import * as React from "react";
const subscribeImg = require("../../assets/waitlistImage.png");
const RU_API = 'https://api.rucapital.money:4500';


export default function Subscribe() {
  const [isEmailable, setEmailable] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");

  return (
    <section id="early-access" className="subscribe">
      <div className="subscribe-left">
        <h1>Revolutionize your <span style={{color: '#4e5acf'}}>finances</span></h1>
        <p className="subscribe-para">
          Hop on board and be among the trendsetters to unlock the full potential of your very own financial sidekick! 🚀
        </p>
        {isEmailable ? <JoinedWaitlist /> : InputButton() }
      </div>
      <div className="subscribe-right">
        <img src={subscribeImg} alt="subscribe-image" />
      </div>
    </section>
  );

  function InputButton() {
    return (
      <>
        <div className="subscribe-container">
            <input
              type="text"
              id="email"
              name="email"
              value={userEmail}
              placeholder="Email address"
              onChange={(e) => {setUserEmail(e.target.value)}}
            />
            <button onClick={clickJoin} onTouchEnd={clickJoin}>Join</button>
        </div>
      </>
    );
  }

  function JoinedWaitlist() {
    return <h3>Thank you. We'll get in touch!!</h3>;
  }

  function clickJoin() {
    console.log("Inside Click");
    
    fetch(`${RU_API}/waitingList`, {
      method: "POST",
      body: JSON.stringify({
        email: userEmail,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        console.log(json);
        setEmailable((prevValue: boolean) => !prevValue);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error as needed
      });
  }
}
