import { useEffect, useRef } from 'react';
import './intro.css';
const homeVideo = require('../../assets/introVideo.mp4');

export default function Intro() {
  return (
    <section id='home' className='intro'>
      <div className='intro-left'>
        <h1>Your Personal <span style={{ color: "#4e5acf" }}>Financial Assistant</span></h1>
        <p>Designed to boost your money game. Let’s make those money moves together!</p>
        <button 
          onClick={() => window.location.replace("/#early-access")} 
          className='btn-join-waitlist'
          >Join Waitlist</button>
      </div>
      <div className='intro-right'>
        <video autoPlay muted playsInline>
          <source src={homeVideo} type='video/mp4' />
        </video>
      </div>
    </section>
  );
}
