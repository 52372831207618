import React, { useEffect, useState } from 'react';
import './assistant-carousal.css';

const img1 = require('../../assets/featureImage1.png');
const img2 = require('../../assets/featureImage2.png');
const img3 = require('../../assets/featureImage3.png');

const images = [
  { src: img1, 
    title:'Finance Uncomplicated! Track, Save, and Thrive', 
    description:['Manage your money like a boss! ', <strong>T</strong>,'rack your spending, set fun ', <strong>S</strong>,'avings goals, and see your finances ', <strong>T</strong>,'hrive - all in one awesome spot 🌟'], 
    alt: 'Feature Image 1' },
  { src: img2, 
    title:'Upgrade your spending game with savvy tips!', 
    description:"Step up your financial game with personalized tips! Whether it's shopping, travel, food, or more, spend wisely and hit your money goals 🎯", 
    alt: 'Feature Image 2' },
  { src: img3, 
    title:'Your Spending Snapshot: Track Your Money in Style!', 
    description:'Visualize your weekly expenses from bites to lights with our sleek analytics feature. Take control and make your spending work for you! 💸', 
    alt: 'Feature Image 3' },
];

export default function ScrollingEffect() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const containerHeight = window.innerHeight;
    let newIndex;

    const scrollSection = document.getElementById("scrollScreens");
    const scrollSectionHeight = scrollSection?.getBoundingClientRect().height;

    const scrollSectionTop = scrollSection?.offsetTop;
    const scrollSectionBottom =
      (scrollSection?.offsetTop ? scrollSection?.offsetTop : 0) +
      (scrollSectionHeight ? scrollSectionHeight : 0) -
      (containerHeight/1.2);
      const scrollRange = ((scrollSectionBottom - scrollSectionTop!) / 3 )
    if (
      scrollDirection === 0 &&
      scrollPosition >= scrollSectionTop! &&
      scrollPosition < scrollSectionBottom
    ) {
      newIndex = Math.floor((scrollPosition - scrollSectionTop!) / scrollRange);
      if (
        newIndex >= 0 &&
        newIndex < images.length &&
        newIndex !== activeIndex
      ) {
        setActiveIndex(newIndex);
      }
    } else if (scrollDirection === 1 && scrollPosition <= scrollSectionBottom) {
      newIndex =
        2 - Math.floor((scrollSectionBottom - scrollPosition) / scrollRange);
      if (
        newIndex >= 0 &&
        newIndex < images.length &&
        newIndex !== activeIndex
      ) {
        setActiveIndex(newIndex);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeIndex]);


  useEffect(()=>{
    const scrollPosition = window.scrollY;
    const containerHeight = window.innerHeight;
    const scrollSection = document.getElementById("scrollScreens");
    const scrollSectionHeight = scrollSection?.getBoundingClientRect().height;
    const scrollSectionTop = scrollSection?.offsetTop;
    const scrollSectionBottom =
      (scrollSection?.offsetTop ? scrollSection?.offsetTop : 0) +
      (scrollSectionHeight ? scrollSectionHeight : 0) -
      containerHeight;

      if(scrollPosition <= scrollSectionTop!){
        setActiveIndex(0)
        setScrollDirection(0)
      }
      if(scrollPosition >= scrollSectionBottom!){
        setActiveIndex(2)
        setScrollDirection(1)
      }
  }, [])

  function isMobile() {
    return window.innerWidth < 768;
  }

  return (
    <div>
      {!isMobile() && (
        <section id='scrollScreens' className="container">
          <div className="left-side">
            <div className='left-side-container'>
              <div className="left-side-text">{images[activeIndex].title}</div>
              <div className='left-side-text-desc'>{images[activeIndex].description}</div>
            </div>
          </div>
          <div className="right-side">
            <div className='right-side-container'>
              <div className='right-side-img-container'>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={index === activeIndex ? 'active' : ''}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
       {isMobile() && (
        <section className='mobile-container'>
          {images.map((image, index) => (
              <div key={index} className='mobile-container-div'>
                  <span className='left-side-text'>
                    {image.title}
                  </span>
                  <div className='mobile-img-comtainer'>
                    <img src={image.src} className='mobile-img' alt={image.alt} />
                  </div>
                  <span className='left-side-text-desc'>
                    {image.description}
                  </span>
              </div>
            ))}
        </section>
       )}
    </div>
  );
}
