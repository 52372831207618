import "./header.css";
const logo = require("../../assets/logo.png");

export default function Header() {
  return (
    <nav className="navbar">
      <a href="#">
        <img src={logo} alt="logo" />
      </a>
      <ul>
        <li>
          <a href="/#early-access">
            Join Waitlist
          </a>
        </li>
      </ul>
    </nav>
  );
}
