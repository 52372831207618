import Header from "./Header";
import Intro from "./Intro";
import Subscribe from "./Subscribe";
// import AssistantDemo from "./AssistantDemo";
import Footer from "./Footer";
import './landingpage.css';
import AssistantCarousal from "./AssistantCarousal";
import ProductInfo from "./productInfo";
import SecuritySection from "./SecuritySection";
import { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
// import AssistantDemoChat from "./ChatBot/AssistantDemoChat";

export default function LandingPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(()=>{
    setTimeout(()=>{
      let isEarlyAccess = searchParams.get("early_access");
      if(isEarlyAccess){
        document.getElementById("early-access")?.scrollIntoView({ block: 'center',  behavior: 'smooth' });
      }
    }, 500)
  },[])

  return (
    <div className='landing-page'>
      <Header />
      <Intro />
      <ProductInfo />
      <AssistantCarousal />
      <SecuritySection /> 
      <Subscribe />
      <Footer />
    </div>

  )
};