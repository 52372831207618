import React from 'react'
import './security-section.css'
const img = require('./../../assets/securityImg.png')

export default function SecuritySection() {
    return(
        <section className='security-section'>
            <div className='img-text-section'>
                <div className='img-text-left-section'>
                    <span className='security-text-title'>It’s all about trust</span><br />
                    <span className='security-text-desc'>Your info's locked down tight with 256-bit encryption. Safe & sound!</span>
                </div>
                <div className='img-text-right-section'>
                    <span className='security-text-title'>Ru’s all eyes no hands</span><br />
                    <span className='security-text-desc'>She’ll see your cash, but won’t get touchy with it!</span>
                </div>
            </div>
            <img className='img-class' src={img} alt='Security Image' />
        </section>
    )
} 