import './footer.css';

export default function Footer() {
  return (
    <footer className='footer'>
        <h1>Coming soon</h1>
          <div className='footer-container'>
            <div className='footer-address'>
              <h2>Resources</h2>
              <p>Blogs</p>
              <p>Newsletters</p>
            </div>
            <div className='footer-address'>
              <h2>Media</h2>
              <p>In the news</p>
              <p>Press Release</p>
            </div>
          </div>
        <h4>© 2024 Ru Capital</h4>
    </footer>
  )
};
